import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

export const InstagramPageTemplate = ({ data }) => {
  const content = data.map(({ node }, index) => {
    const { link, localImage, images, videos, type, caption } = node
    return (
      <div className="tile is-4 insta-item" key={`inst-${index}`}>
        {(type === 'image' || type === 'carousel') && (
          <figure className="image">
            <a href={link} target="_blank" rel="noopener noreferrer">
              <img className="has-ratio" src={
                localImage.childImageSharp
                ? localImage.childImageSharp.fluid.src
                : images.standard_resolution.url
              }
              />
            </a>
            {caption && (<figcaption style={{ display: 'none' }}>{caption.text}</figcaption>)}
          </figure>
        )}
        {type === 'video' && (
          <figure className="image is-1by1">
            <a href={link} target="_blank" rel="noopener noreferrer">
              <video className="has-ratio" controls autoPlay muted src={videos.standard_resolution.url} />
            </a>
          </figure>
        )}
      </div>
    )
  })

    return (
    <section className="container">
      <div className="tile is-ancestor" style={{ flexWrap: 'wrap' }}>
        {content}
      </div>
    </section>
  )
}

const InstagramPage = ({ data }) => {
  const { allInstagramContent: { edges } } = data
  return (
    <Layout>
      <InstagramPageTemplate data={edges} />
    </Layout>
  )
}

export default InstagramPage

export const instagramPageQuery = graphql`
  query InstagramPage {
    allInstagramContent {
      edges {
        node {
         localImage{ 
           childImageSharp {
             fluid(maxHeight: 500, maxWidth: 500 quality: 90) {
               ...GatsbyImageSharpFluid_withWebp
             }
           }
         }
         link
         caption {
           text
         }
         likes {
          count
         }
         videos {
          standard_resolution {
            url
            width
            height
          }
         }
         type
         images {
           standard_resolution {
             url
          }
        }
      }
    }
  }
}
`
